<template>
  <v-card>
    <v-card-title class="dark-background">
      <v-btn
        text
        color="white"
        data-cy="cancelDateSelectionButton"
        @click="cancel"
      >
        {{ $t('buttons.cancel')}}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="white"
        data-cy="saveDateSelectionButton"
        @click="applyCustomDateRange"
      >
        {{ $t('buttons.save') }}
      </v-btn>
    </v-card-title>
    <div class="range-container">
      <div class="range-label">{{ $t('map.historyDialog.datePicker.rangeLabel') }}</div>
      <div class="range-value">
        <span :class="{ 'default-value': !customDateRange.start }"  data-cy="customStartDate">
          {{ formatDate(customDateRange.start, $t('map.historyDialog.datePicker.defaultRangeStart')) }}
        </span>
        <span :class="{ 'default-value': !customDateRange.start }"> - </span>
        <span :class="{ 'default-value': !customDateRange.end }" data-cy="customEndDate">
          {{ formatDate(customDateRange.end, $t('map.historyDialog.datePicker.defaultRangeEnd')) }}
        </span>
        <v-icon
          class="clear-icon"
          @click="clearDates"
          v-if="customDateRangeModel.length"
          small
          data-cy="clearDateSelectionButton"
        >
          mdi-close-circle-outline
        </v-icon>
      </div>
    </div>
    <v-date-picker
      v-model="customDateRangeModel"
      full-width
      no-title
      range
      color="primary"
      :min="minCustomDate"
      :max="maxCustomDate"
      :disabled="!maxAllowedMS"
      data-cy="datePickerSelector"
    />
  </v-card>
</template>

<script>
import { getTodayDateObject } from '@/services/data-export.js'
import { SlugsEnum } from '@/permissions/SlugsEnum.js'

const DATE_TODAY = getTodayDateObject()
const ONE_DAY_MS = 1000 * 60 * 60 * 24
const THIRTY_DAYS_MS = 30 * ONE_DAY_MS
// using 94 days because we also include the partial day of data from the current date
// so this imposes a real total limit of 95 full days in accordance with the devices API
const NINETY_FOUR_DAYS_MS = 94 * ONE_DAY_MS
const ABSOLUTE_DAYS_AGO_LIMIT = 397
const ABSOLUTE_MIN_DATE = new Date(
  DATE_TODAY.getTime() - ABSOLUTE_DAYS_AGO_LIMIT * ONE_DAY_MS
)

export default {
  name: 'datePicker',
  props: {
    devices: Array,
    permissions: Object,
    initialDateRangeModel: Array,
  },
  data() {
    return {
      customDateRangeModel: this.getInitialDateRange(),
    }
  },
  computed: {
    customDateRange() {
      let range = {
        start: null,
        end: null,
      }
      // convert ISO8091 date strings back to dates
      const customDates = this.customDateRangeModel.map(
        (d) => new Date(d.split('-'))
      )
      switch (customDates.length) {
        case 0:
          break
        case 1:
          range.start = new Date(customDates[0])
          break
        case 2: {
          // sort oldest to newest rather than by order of selection
          customDates.sort((a, b) => a - b)
          // extend end date timestamp to the end of that day or the current time, whichever is earlier
          const endDateEOD = new Date(customDates[1].getTime() + ONE_DAY_MS - 1)
          customDates[1] = new Date(Math.min(...[DATE_TODAY, endDateEOD])) // spread operator implicitly converts dates to numbers
          range = {
            start: customDates[0],
            end: customDates[1],
          }
          break
        }
      }

      return range
    },
    permissionSet() {
      if (this.permissions) {
        return [
          ...new Set(
            this.devices.map((device) => this.permissions[device.id]).flat(1)
          ),
        ]
      }
      return []
    },
    hasThreeMonthsPermission() {
      return this.permissionSet.includes(
        SlugsEnum.HistoryChartExecuteDataThreeMonths
      )
    },
    hasMonthPermission() {
      return this.permissionSet.includes(
        SlugsEnum.HistoryChartExecuteDataMonth
      )
    },
    hasDayPermission() {
      return this.permissionSet.includes(
        SlugsEnum.HistoryChartExecuteDataDay
      )
    },
    maxAllowedMS() {
      if (this.hasThreeMonthsPermission) {
        return NINETY_FOUR_DAYS_MS
      } else if (this.hasMonthPermission) {
        return THIRTY_DAYS_MS
      } else if (this.hasDayPermission) {
        return ONE_DAY_MS
      } else {
        return 0
      }
    },
    minCustomDate() {
      if (this.customDateRangeModel?.length === 1) {
        const minTime =
          new Date(this.customDateRangeModel[0]).getTime() - this.maxAllowedMS
        if (minTime > ABSOLUTE_MIN_DATE.getTime()) {
          return this.formatDateISO8601(new Date(minTime))
        }
      }

      return this.formatDateISO8601(ABSOLUTE_MIN_DATE)
    },
    maxCustomDate() {
      if (this.customDateRangeModel?.length === 1) {
        const maxTime =
          new Date(this.customDateRangeModel[0]).getTime() + this.maxAllowedMS
        if (maxTime < DATE_TODAY.getTime()) {
          return this.formatDateISO8601(new Date(maxTime))
        }
      }

      return this.formatDateISO8601(DATE_TODAY)
    },
  },
  methods: {
    applyCustomDateRange() {
      if (!this.customDateRangeModel?.length) {
        return this.cancel()
      }

      // Send end date as the start date when the user selects one day.
      if (this.customDateRangeModel.length === 1) {
          this.customDateRangeModel.push(this.customDateRangeModel[0])
      }

      Vue.nextTick(() => {
        this.$emit('done', {
          customDateRangeModel: this.customDateRangeModel,
          customDateRange: this.customDateRange,
        })
      })
    },
    cancel() {
      this.$emit('done', undefined)
    },
    clearDates() {
      this.customDateRangeModel = []
    },
    // convert a Date object to mmm DD YYYY format or return defaultValue
    formatDate(date, defaultValue) {
      const formatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      })
      return date ? formatter.format(date) : defaultValue
    },
    formatDateISO8601(date) {
      // Convert a Date object to YYYY-MM-DD format
      return !isNaN(date) ? date.toISOString().split('T')[0] : null
    },
    getInitialDateRange() {
      if (this.initialDateRangeModel?.length === 2) {
        return this.initialDateRangeModel
      }

      return [
        this.formatDateISO8601(new Date(DATE_TODAY.getTime() - ONE_DAY_MS)),
        this.formatDateISO8601(DATE_TODAY),
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/global-variables.scss';

  .dark-background {
    background-color: black
  }

  .range-container {
    background-color: $white-smoke-color; /* Light gray background */
    padding: 8px 16px;
    text-align: left;
    display: flex;
    flex-direction: column;
  }

  .range-label {
    font-size: 12px;
    text-transform: uppercase;
    color: black;
  }

  .range-value {
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 4px;
    display: flex;
    align-items: center; /* Center content vertically */
    gap: 8px; /* Space between text and the clear icon */
  }

  .range-value .default-value {
    color: #9e9e9e; /* Gray text for default values */
  }

  .range-value span:not(.default-value) {
    color: black; /* Black text when values are present */
  }
</style>
