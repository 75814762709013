var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popover-toolbar" },
    [
      _c("connection-status", { attrs: { deviceId: _vm.deviceId } }),
      _c("battery-status", { attrs: { deviceId: _vm.deviceId } }),
      _c("eol-alert", { attrs: { deviceId: _vm.deviceId } }),
      _c("device-alerts", { attrs: { deviceId: _vm.deviceId } }),
      _c("device-notifications", {
        attrs: { deviceId: _vm.deviceId, deviceName: _vm.name },
      }),
      _c("communication-status", { attrs: { deviceId: _vm.deviceId } }),
      _c("offline-studies-status", { attrs: { deviceId: _vm.deviceId } }),
      _c("live-studies-status", { attrs: { deviceId: _vm.deviceId } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }