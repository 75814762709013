import { getLatestDeviceStateDataValueFromStates } from '@/helpers/devices/stateHelper'

export const getLatestUserCalValueFromStudyStates = (states, key) => {
  const userCal = getLatestDeviceStateDataValueFromStates(states, 'user_cal')
  if (userCal?.length) {
    for (const userCalItem of userCal) {
      if (userCalItem?.Key === key) {
        for (const userCalItemValue of userCalItem.Value) {
          if (userCalItemValue.Key === 'value') {
            return userCalItemValue.Value
          }
        }
      }
    }
  }

  return null
}

export const getCalibrationDateFromStudyStates = (states) => {
  return getLatestDeviceStateDataValueFromStates(states, 'calibration_date')
}

export const getFlowCalibrationFactorFromStudyStates = (states) => {
  return getLatestDeviceStateDataValueFromStates(states, 'flow_calibration_factor')
}

export const getPhotometricCalFactorFromStudyStates = (states) => {
  return getLatestUserCalValueFromStudyStates(states, 'photometric_cal_factor')
}

export const getSizeCalFactorFromStudyStates = (states) => {
  return getLatestUserCalValueFromStudyStates(states, 'size_cal_factor')
}
