export function replaceData(obj, prop, data) {
  if (obj != undefined && data != undefined) {
    Vue.set(obj, prop, data)
  }
  return obj
}

export function deleteData(obj, key) {
  if (obj != undefined) {
    Vue.delete(obj, key)
  }
  return obj
}

// Replace the data if the value is valid (not null or undefined) or delete it otherwise.
// One example of a store value that requires this function is lastTelemetryStudyId.
// When a user creates an offline/live study, a study_id is stored in latest-device-telemetry collection.
// This study_id is deleted from latest-device-telemetry in Mongo for continuous monitoring processes.
// Since reactivity does not work with Vue.set when passing null or undefined values, it can be deleted or set to an empty value.

export const replaceOrDeleteData = (obj, prop, data) => {
  if (obj && typeof obj === 'object' && prop) {
    if (data !== undefined && data !== null) {
      return replaceData(obj, prop, data)
    } else {
      return deleteData(obj, prop)
    }
  }

  return obj
}
