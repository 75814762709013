<template>
  <div class="popover-toolbar">
    <connection-status :deviceId="deviceId" />
    <battery-status :deviceId="deviceId" />
    <eol-alert :deviceId="deviceId" />
    <device-alerts :deviceId="deviceId" />
    <device-notifications :deviceId="deviceId" :deviceName="name" />
    <communication-status :deviceId="deviceId" />
    <offline-studies-status :deviceId="deviceId" />
    <live-studies-status :deviceId="deviceId" />
  </div>
</template>

<script>
import eolAlert from './eolAlert.vue'
import connectionStatus from './connectionStatus.vue'
import deviceAlerts from './deviceAlerts.vue'
import deviceNotifications from './deviceNotifications.vue'
import BatteryStatus from '@/components/icons/BatteryStatus.vue'
import CommunicationStatus from '@/components/icons/CommunicationStatus.vue'
import OfflineStudiesStatus from '@/components/icons/OfflineStudiesStatus.vue'
import LiveStudiesStatus from '@/components/icons/LiveStudiesStatus.vue'

export default {
  components: {
    'device-alerts': deviceAlerts,
    'device-notifications': deviceNotifications,
    'eol-alert': eolAlert,
    'connection-status': connectionStatus,
    'battery-status': BatteryStatus,
    'communication-status': CommunicationStatus,
    'offline-studies-status': OfflineStudiesStatus,
    'live-studies-status': LiveStudiesStatus,
  },
  props: {
    deviceId: String,
  },
  data() {
    return {}
  },
  computed: {
    name: function () {
      return this.$store.getters['devices/getNameByDeviceId'](this.deviceId)
    },
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.popover-toolbar {
  white-space: nowrap;

  ::v-deep .v-icon {
    font-size: 22px;
  }

  ::v-deep img {
    width: 22px;
  }

  @media (max-width: 480px) {
    ::v-deep .v-icon {
      font-size: 21px;
    }

    ::v-deep img {
      width: 21px;
    }
  }
}
</style>
