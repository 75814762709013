import { isValidObject } from '@/helpers/common/validationHelper'
import { getLatestObjectByTimestamp } from '@/helpers/common/helper'

export const getDeviceStateDataValueFromState = (state, key) => {
  if (isValidObject(state)) {
    const deviceStateData = state.device_state_data
    if (isValidObject(deviceStateData)) {
      const deviceStateItem = deviceStateData[key]
      if (isValidObject(deviceStateItem)) return deviceStateItem.value
    }
  }

  return null
}

export const getLatestDeviceStateDataValueFromStates = (states, key) => {
  const latestState = getLatestObjectByTimestamp(states, 'timestamp')
  return getDeviceStateDataValueFromState(latestState, key)
}
