import {
  getMeasurementTypeKeyByName,
  allMeasurementStatistics,
} from '@/services/device-measurements.js'

const getSensorReadings = (sensor) => {
  if (sensor?.measurements) {
    let readings = {}
    const measurements = sensor.measurements
    for (const [measurementKey] of Object.entries(measurements)) {
      const measurement = measurements[measurementKey]
      if (measurement) {
        readings = {
          ...readings,
          [measurementKey]: {
            name: measurementKey,
            timestamp: measurement.timestamp,
            value: measurement.value,
          },
        }
      }
    }
    return readings
  }
  return null
}

export const getTelemetryReadingsObject = (telemetry) => {
  if (telemetry?.sensors) {
    const sensors = telemetry.sensors
    let readings = {}
    for (const [sensorKey] of Object.entries(sensors)) {
      const sensor = sensors[sensorKey]
      readings = {
        ...readings,
        ...getSensorReadings(sensor)
      }
    }

    return readings
  }

  return null
}

export const getAllTelemetryStatisticsReadingsObject = (telemetry) => {
  const readings = getTelemetryReadingsObject(telemetry)
  const measurementStatistics = {}

  if (readings) {
    for (const [measurementName] of Object.entries(readings)) {
      const measurementKey = getMeasurementTypeKeyByName(measurementName)
      if (allMeasurementStatistics.indexOf(measurementKey) !== -1) {
        measurementStatistics[measurementName] = readings[measurementName]
      }
    }
  }

  return Object.entries(measurementStatistics).length ? measurementStatistics : null
}
